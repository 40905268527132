<template>
  <div id="app" :class="[state]">
    <div class="wrapper">
      <div class="title">
        <!-- Лого. Можно закинуть другой в папку src/assets/img и поменять -->
        <img src="@/assets/img/qr-logo.svg" alt="Проверка QR-кода" class="title__logo"/>
      </div>

      <main>
        <Camera :key="cameraKey" :disabled="!!error" @detect="onDetect" @init="onCameraInit"/>

        <!-- Текст, который показывается под сканером по умолчанию -->
        <div class="scanning" v-if="state==='scanning'">
          <p><b>Наведите камеру на QR-код</b></p>
        </div>

        <div class="result" v-if="scanResult">
          <div v-if="allowedTypes.includes(scanResult.type)">
            <button v-if="state !== 'scan-other' && state !== 'scan-type-mismatch' && decrypt.state !== 'ready'"
                    :class="['result-decrypt', {loading: decrypt.state==='loading'}]"
                    @click="decryptReceipt">
              <span v-if="decrypt.state==='loading'">Расшифровываем...</span>
              <span v-else>Расшифровать код</span>
            </button>
            <!-- Если от сервера пришёл нормальный ответ -->
            <div v-if="decrypt.state === 'ready'">
              <a class="btn" :href="urlParams.get('bot_url')">Вернуться к боту</a>
              <p>Готово! Теперь вернитесь к боту.</p>
            </div>
            <!-- Если сервер вернул ошибку -->
            <p v-if="decrypt.state === 'error'" class="decrypt-error">
              <span v-if="decrypt.error" v-text="decrypt.error"></span>
              <span v-if="!decrypt.error">Что-то пошло не так, отправьте на расшифровку ещё раз</span>
            </p>
          </div>

          <div v-if="scanResult && decrypt.state !== 'ready'">
            <button v-if="state === 'scan-other' || state === 'scan-type-mismatch'"
                    @click="resetCamera">Отсканировать другой код
            </button>
            <div v-if="state !== 'scan-other' && state !== 'scan-type-mismatch'">
              <!-- Если отсканирован ОФД-код (и параметр type в URL страницы его разрешает) -->
              <p v-if="scanResult.type==='ofd'" v-html="config.strings.ofdAnswer"></p>
              <!-- Если отсканирован ЕГАИС-код (и параметр type в URL страницы его разрешает) -->
              <p v-else-if="scanResult.type==='egais'"
                 v-html="config.strings.egaisAnswer"></p>
              <!-- Если отсканировано что-то другое (или ОФД/ЕГАИС, хотя в URL он не разрешён) -->
              <p v-else v-html="config.strings.otherAnswer"></p>
            </div>
          </div>
        </div>

        <!-- Сообщения об ошибках камеры, открытии страницы по ссылке без параметров, отсутствии сети -->
        <div class="error" v-if="error||offline">
          <div class="error__modal" v-if="error">{{ error }}</div>
          <div class="error__modal error__modal_offline" v-else-if="offline">Нет подключения к интернету. Пожалуйста,
            убедитесь, что вы подключены к сети.
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Camera from "./components/Camera";
import config from '../scanner.config.json';

export default {
  components: {Camera},
  data() {
    return {
      state: 'scanning',
      scanResult: null,
      error: null,
      offline: false,
      decrypt: {
        error: null,
        state: 'idle'
      },
      cameraKey: 0
    }
  },
  methods: {
    onCameraInit(result) {
      if (!this.error) {
        if (result?.error)
          if (result.error.name === 'NotAllowedError') {
            this.error = 'Без доступа к камере приложение не может работать. Пожалуйста, разрешите доступ к камере в настройках сайта и обновите страницу.'
          } else {
            this.error = 'Не удалось подключиться к камере. Попробуйте открыть сайт в другом браузере.'
          }
      }
    },
    onDetect(result) {
      // если уже расшифровали код, не реагируем на новые
      if (this.decrypt.state === 'ready') return
      // проверка на ОФД
      if (result.match(/^t=[\w&=.,]+/)) {
        this.state = this.allowedTypes.includes('ofd') ? 'scan-valid' : 'scan-type-mismatch'
        this.scanResult = {value: result, type: 'ofd'}
      }
      // проверка на ЕГАИС
      else if (result.match(/^http[s]?:\/\/check\.egais\.ru[/?].*/)) {
        this.state = this.allowedTypes.includes('egais') ? 'scan-valid' : 'scan-type-mismatch'
        this.scanResult = {value: result, type: 'egais'}
      }
      // код не совпал ни с одним форматом
      else {
        this.state = 'scan-other'
        this.scanResult = {value: result, type: 'other'}
      }
    },
    decryptReceipt() {
      // отправка запроса к API
      this.decrypt.state = 'loading'
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 60000); // таймаут запроса 1 минут

      fetch(this.urlParams.get('callback_url'), {
        method: 'POST',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          datetime: new Date().getTime(),
          project_id: this.urlParams.get('project_id'),
          user_id: this.urlParams.get('user_id'),
          qr_code_data: this.scanResult.value,
          bot_url: this.urlParams.get('bot_url')
        })
      }).then(r => {
        clearTimeout(timeoutId);
        // если сервер вернул ответ с кодом 2xx, то всё хорошо
        if (r.ok) {
          this.decrypt.state = 'ready';
          this.decrypt.error = null;
        } else {
          r.text().then((text) => {
            this.decrypt.state = 'error';
            this.decrypt.error = text;
            this.state = 'scan-other';
          });
        }
      }).catch(() => {
        this.decrypt.state = 'error'
        this.decrypt.error = null;
      })
    },
    resetCamera() {
      this.scanResult = null
      this.state = 'scanning'
      this.cameraKey = Math.random()
      this.decrypt = {
        error: null,
        state: 'idle'
      };
    }
  },
  computed: {
    config() {
      return config.projects[this.urlParams.get('project_id')]
    },
    urlParams() {
      return new URLSearchParams(window.location.search)
    },
    allowedTypes() {
      return this.urlParams.get('type')?.split('-') || []
    }
  },
  mounted() {
    if (!window.navigator.onLine) this.offline = true;

    addEventListener('offline', () => {
      this.offline = true
    })
    addEventListener('online', () => {
      this.offline = false
    })

    var failed = false;
    try {
      var domain_sp = new URL(this.urlParams.get('callback_url')).hostname.split('.');
      var domain = domain_sp[domain_sp.length - 2] + '.' + domain_sp[domain_sp.length - 1];
    } catch (e) {
      failed = true;
    }
    var valid_domains = process.env.VUE_APP_API_VALID_DOMAIN_ENDPOINT
    // если в URL не хватает какого-то из параметров, сканер не запустится
    if (failed
        || !this.urlParams.has('project_id')
        || !this.urlParams.has('user_id')
        || !this.urlParams.has('type')
        || !this.urlParams.has('bot_url')
        || !(valid_domains.includes(domain))
        || !this.config) {
      this.error = 'Пожалуйста, откройте страницу по ссылке, которую прислал бот.'
    }
  }
}
</script>


<style lang="less">
@import (css) url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&family=Source+Code+Pro:wght@400;600&display=swap');

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: currentColor;
}

#app {
  font-family: Manrope, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  width: 100%;
  min-height: 100%;

  --bg: #FDFDFD;
  --text: #1F1F1F;
  --border: #e2e2e2;

  &.scan-type-mismatch {
    --bg: #EBDFF0;
    --text: #86609D;
  }

  &.scan-valid {
    --bg: #BDFFC8;
    --text: #097139;
    --border: #90E29D;
  }

  &.scan-other, &.scan-error {
    --bg: #FFDDDB;
    --text: #FF363D;
    --border: #E4C0BE;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 10px 2rem;
  max-width: 800px;
  margin: auto;
  min-height: 100%;
  flex: 1;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 0 auto 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: var(--text);

  &__logo {
    display: block;
    width: 200px;
  }
}

.scanning {
  p {
    color: var(--text);

    &.secondary {
      color: #999;
      font-size: .96rem;
    }
  }
}

.result {
  color: var(--text);

  &-decrypt {

  }

  .decrypt-error {
    color: #ff5b61;
    font-weight: 700;
    font-size: .9rem;
  }
}

button, a.btn {
  border: none;
  background-color: var(--text);
  color: white;
  width: 100%;
  max-width: 300px;
  margin: auto;
  padding: 12px 15px;
  border-radius: 6px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &.loading::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid currentColor;
    border-radius: 16px;
    border-left-color: rgba(0, 0, 0, 0);
    margin-right: 10px;
    animation: spin 1s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}

.error {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    background: white;
    padding: 20px;
    line-height: 1.5;
    border-radius: 8px;
    width: calc(100% - 40px);
    max-width: 800px;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3691 22.4688C17.892 22.4688 22.3691 17.9916 22.3691 12.4688C22.3691 6.9459 17.892 2.46875 12.3691 2.46875C6.84629 2.46875 2.36914 6.9459 2.36914 12.4688C2.36914 17.9916 6.84629 22.4688 12.3691 22.4688Z' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.3691 8.46875V12.4688' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.3691 16.4688H12.3791' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      display: block;
    }

    &_offline::before {
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M1.24219 1.30859L23.2422 23.3086' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.9629 11.3672C17.782 11.7669 18.5479 12.2675 19.2429 12.8572' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.24219 12.8588C6.72427 11.6196 8.50806 10.795 10.4122 10.4688' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.9531 5.36029C13.0955 5.18765 15.2508 5.44845 17.2902 6.12711C19.3296 6.80576 21.2113 7.88836 22.8231 9.31029' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.66211 9.30969C3.04927 8.08354 4.63981 7.10891 6.36211 6.42969' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.77148 16.4173C9.78669 15.6961 11.0012 15.3086 12.2465 15.3086C13.4918 15.3086 14.7063 15.6961 15.7215 16.4173' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.2422 20.3086H12.2522' stroke='%23EE6C6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white' transform='translate(0.242188 0.308594)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
}

.links {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  a {
    text-align: center;
    flex: 1;
    color: #1f1f1f;
    padding: 15px 0;

    svg {
      height: 1.6rem;
      width: auto;
      display: block;
      margin: auto auto 15px;
    }

    span {
      font-size: 0.78rem;
      font-weight: 600;
      display: block;
      line-height: 1.5;
    }
  }

  > i {
    display: block;
    width: 1px;
    background-color: #35353533;
    margin: 0 15px;
  }
}

footer {
  margin-top: auto;
  z-index: 99;

  a {
    display: block;
  }

  img[src*='/yadda.'] {
    height: 1rem;
  }

  img[src*='simployal'] {
    height: 1.5rem;
    margin-bottom: 10px;
  }
}
</style>
